import React from "react"
import PropTypes from "prop-types"

const NavItem = ({ children }) => (
  <>
    <li className="nav-item active">{children}</li>
  </>
)

NavItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NavItem
