import React from "react"

const Address = () => (
  <address className="address dubbelbit">
    <a href="mailto:info@dubbelbit.nl">info@dubbelbit.nl</a> <br />
    <span>Dubbelbit BV</span> <br />
    Smirnoffweg 36 <br />
    3088 HE Rotterdam <br />
    <span className="tab">Telefoon</span>
    <span>:</span> 010-8209750 <br />
    <span className="tab">Telefax</span>
    <span>:</span> 010-8209751 <br />
    <span className="tab">KVK</span>
    <span>:</span> 24456597
  </address>
)

export default Address
