import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import HeaderImage from "./headerImage"

const Header = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subtitle
            description
          }
        }
      }
    `
  )

  return (
    <>
      <header id="site-home-header" className="site-home-header">
        <HeaderImage>
          <div className="container-fluid">
            <div className="site-header-content ml-lg-4 p-4">
              <div className="site-header-content-title mb-3">
                <h3 className="site-title dubbelbit">
                  {site.siteMetadata.subtitle}
                </h3>
                <h5 className="site-description">
                  {site.siteMetadata.description}
                </h5>
              </div>
              <Link to="services" className="btn btn-xs btn-primary">
                What we do
              </Link>
              <a
                href="#contact-form"
                style={{ marginLeft: 5 }}
                className="btn btn-xs btn-primary"
              >
                Connect
              </a>
            </div>
          </div>
        </HeaderImage>
      </header>
    </>
  )
}

export default Header
