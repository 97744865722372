import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const HeaderImage = ({ children }) => {
  const images = useStaticQuery(graphql`
    query rotterdam {
      rotterdam: file(relativePath: { eq: "rotterdam.jpg" }) {
        childImageSharp {
          xl: fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
          l: fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
          m: fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const BackgroundImage = styled.div`
     {
      min-height: 700px;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      opacity: 0.9;
      width: 100%;
      height: 100%;
      max-height: 700px;
      background-image: url(${images.rotterdam.childImageSharp.xl.src});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    @media (max-width: 1400px) {
       {
        min-height: 600px;
      }

      &:before {
        max-height: 600px;
      }
    }

    @media (max-width: 1200px) {
       {
        min-height: 500px;
      }

      &:before {
        max-height: 500px;
      }
    }

    @media (max-width: 1000px) {
       {
        min-height: 400px;
      }

      &:before {
        max-height: 400px;
        background-image: url(${images.rotterdam.childImageSharp.l.src});
        background-image: -webkit-image-set(
          url(${images.rotterdam.childImageSharp.l.src}) 1x,
          url(${images.rotterdam.childImageSharp.xl.src}) 2x
        );
        background-image: image-set(
          url(${images.rotterdam.childImageSharp.l.src}) 1x,
          url(${images.rotterdam.childImageSharp.xl.src}) 2x
        );
      }
    }

    @media (max-width: 600px) {
      &:before {
        background-image: url(${images.rotterdam.childImageSharp.m.src});
        background-image: -webkit-image-set(
          url(${images.rotterdam.childImageSharp.m.src}) 1x,
          url(${images.rotterdam.childImageSharp.l.src}) 2x
        );
        background-image: image-set(
          url(${images.rotterdam.childImageSharp.m.src}) 1x,
          url(${images.rotterdam.childImageSharp.l.src}) 2x
        );
      }
    }
  `
  return (
    <BackgroundImage className="outer site-header-background">
      {children}
    </BackgroundImage>
  )
}

export default HeaderImage
