import React from "react"
import Address from "./address"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const Footer = () => {
  const bgFooterImage = useStaticQuery(graphql`
    query bgFooterImage {
      bgFooter: file(relativePath: { eq: "bg-footer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 125, maxHeight: 80) {
            srcWebp
          }
        }
      }
    }
  `)

  const Footer = styled.footer`
    &:after {
      margin-top: 3rem;
      content: "";
      display: block;
      height: 80px;
      background-image: url(${bgFooterImage.bgFooter.childImageSharp.fluid
        .srcWebp});
    }
  `
  return (
    <>
      <Footer id="footer" className="footer mt-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <h5>Resources</h5>
              <ul className="list-unstyled text-small">
                <li>
                  <a
                    className="text-muted"
                    href="https://github.com/dubbelbit/"
                  >
                    Github
                  </a>
                </li>
                <li>
                  <a
                    className="text-muted"
                    href="https://www.linkedin.com/company/dubbelbit-bv/"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    className="text-muted"
                    href="https://dotnetfromthemountain.com"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>Support</h5>
              <ul className="list-unstyled text-small">
                <li>
                  <a className="text-muted" href="https://mailstorage.nl/owa">
                    Webmail
                  </a>
                </li>
                <li>
                  <a
                    className="text-muted"
                    href="http://www.doublebit.nl/files/TeamViewerQS.exe"
                  >
                    Teamviewer
                  </a>
                </li>
                <li>
                  <a
                    className="text-muted"
                    href="http://www.doublebit.nl/files/TeamViewerQS.dmg"
                  >
                    Teamviewer (MAC)
                  </a>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>About</h5>
              <ul className="list-unstyled text-small">
                <li>
                  <a className="text-muted" href="/about-us/#team">
                    Team
                  </a>
                </li>
                <li>
                  <a className="text-muted" href="/about-us/#contact-form">
                    Connect
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-sm-6 col-md-4 col-lg-3">
              <Address />
            </div>
          </div>
        </div>
      </Footer>
    </>
  )
}

export default Footer
