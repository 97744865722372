/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Navigation from "./navigation"
import Header from "./header"
import Footer from "./footer"
import Copyright from "./copyright"
import { withPrefix } from "gatsby"

const Layout = ({ children }) => {
  const isHomePage =
    typeof window !== "undefined" &&
    window.location.pathname === withPrefix("/")
  return (
    <>
      <Navigation />

      {isHomePage && <Header />}

      <main
        style={{
          paddingTop: !isHomePage ? 64 : 0,
        }}
        role="main"
      >
        {children}
      </main>

      <Footer />
      <Copyright />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
