import React from "react"

const Copyright = () => (
  <>
    <div className="container-fluid my-3">
      <p>© {new Date().getFullYear()} DubbelBit B.V.</p>
    </div>
    <div className="container-fluid">
      <p>
        Op al onze aanbiedingen, verkopen en leveringen zijn, met uitsluiting
        van alle andere voorwaarden, onze algemene voorwaarden van toepassing,
        gedeponeerd bij de Kamer van Koophandel NR. 24456597 te Rotterdam.{" "}
        <br />
        On all our offerings, sales and shipments, excluding all other
        conditions, our general conditions are applicable, these are registered
        at the Chamber of Commerce No. 24456597 in Rotterdam.
      </p>
    </div>
  </>
)

export default Copyright
