import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import NavItem from "./navItem"

const Navigation = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            src
          }
        }
      }
    }
  `)

  return (
    <>
      <nav
        id="navigation"
        className="navbar navbar-expand-md fixed-top bg-dubbelbit navigation"
      >
        <div className="container-fluid">
          <Link className="navbar-brand company-title dubbelbit" to="/">
            DUBBELBIT BV
          </Link>
          <button
            className="navbar-dark navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto">
              <NavItem>
                <Link className="nav-link" to="/services">
                  Services
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/customers">
                  Customers
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/about-us">
                  About us
                </Link>
              </NavItem>
              <li className="nav-item d-none d-md-block">
                <img
                  src={data.logo.childImageSharp.fluid.src}
                  alt="logo"
                  className="img-fluid"
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navigation
